@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  background:rgb(241,243,233);
}

ul {
  list-style-type: none;
}

.containerh {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
/* 
.navbar {
  background: #040224;
  color: #fff;
  height: 60px;
} */


iframe {
  width: 100%;
}

.lg {
  font-size: 3rem;
}

.navbar .logo {
  font-size: x-large;
  font-weight: bold;
  color: gold;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.navbar ul {
  display: flex;
}

.navbar ul li {
  margin-left: 20px;
}

.navbar a:hover {
  color: lightblue;
}

#mainNavigation a {
  font-family: 'Cabin', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px rgb(255, 255, 255,0.7);
}

.dropdown-menu {
  background: #04022498;
}

a.dropdown-toggle {
  color: #dfdfdf !important;
}

a.dropdown-item:hover {
  color: #04022488 !important;
}

.nav-item a {
  color: linen;
}

.nav-item a:hover {
  color: goldenrod;
}

.nav-item {
  min-width: 10vw;
}

#mainNavigation {
  max-height: 10%;
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 123;
}

#navbarNavDropdown.collapsing .navbar-nav,
#navbarNavDropdown.show .navbar-nav {
  background: #04022498;
}

/* Carousel style */
.container-fluid {
  margin-top: 30px
}

.carousel-item img {
  height: 500px;
}

.carousel-caption {
  font-size: 30px;
}

#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

/* info */
.info {
  font-family: 'Poppins', sans-serif;
}

.info-heading {
  max-width: 500px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}





/* about */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 50px;
}

.card1 {
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 100%;
  border: 1px blue solid;
  background-color: #f4f4f4;
  color: #474747;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  /* background-image: url(/images/mh.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color; */
}

.mh {
  margin-left: 100px;
}

.card2 {

  color: black;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 50px;
  margin-top: 10px;
  height: 100%;
}

.card1 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  margin: 0;

}

.btns {
  padding: 10 30px;
  cursor: pointer;
  background-color: gold;
  border-radius: 5px;
  border: none;
}

.logos h1 {
  font-size: x-large;
  font-weight: bold;
  color: gold;
}

/* patners */
.partners {
  margin-top: 50px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.partners .flex {
  flex-wrap: wrap;
}

.partners .card {
  text-align: center;
  margin: 18px 10px 40px;
  transition: transform 0.2s ease-in;
}

.partners .card h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.partners .card:hover {
  transform: translateY(-15px);
}

/* footer */
#footer {
  background: #040224;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px
}

#footer .footer-top {
  background: #040224;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 60px 0 30px 0
}

#footer .footer-top .footer-info {
  margin-bottom: 30px
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s
}

#footer .footer-top .social-links a:hover {
  background: #e96b56;
  color: #fff;
  text-decoration: none
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px
}

#footer .footer-top .footer-links {
  margin-bottom: 30px
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ec7f6d;
  font-size: 18px;
  line-height: 1
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1
}

#footer .footer-top .footer-links ul a:hover {
  color: #e96b56
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px
}

#footer .footer-top .footer-contact p {
  line-height: 26px
}

#footer .copyright {
  text-align: center;
  padding-top: 30px
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff
}