.wrapper {
  height: 100vh;
  overflow-x: hidden;
  background-size: cover;
  width: 100%
}

.overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8)
}

.overlay p {
color: darkslategray;
}

.contact-us {
  margin-top: 50px;
  margin-bottom: 50px
}

.contact-us h3,
p {
  color: darkslategray;
}

.address {
  margin-top: 14px !important;
  margin-left: 10px
}

.address span {
  color: #7B1FA2
}

.icons {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center
}

.icons i {
  font-size: 20px
}

.forms {
  padding: 20px
}

.inputs input {
  margin-bottom: 13px;
  border: none;
  border-bottom: 2px solid #eee
}

.inputs input:focus {
  margin-bottom: 13px;
  border: none;
  border-bottom: 2px solid #7B1FA2;
  box-shadow: none
}

.inputs textarea {
  margin-bottom: 13px;
  border: none;
  border-bottom: 2px solid #eee;
  width: 100%;
  resize: none
}

.inputs textarea:focus {
  margin-bottom: 13px;
  border: none;
  border-bottom: 2px solid #7B1FA2;
  box-shadow: none;
  resize: none
}

.form-control {
  padding: .375rem .25rem
}