/* @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Open+Sans:wght@400;700&display=swap');
body{
	background: rgba(0,0,0,.075);
	}
.container1{
	margin-top: 50px;
	margin-bottom: 50px;
}
.col-md-4{
	background: #F9F8F4;
	text-align: center;
	border-radius: 12px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.logo{
	text-align: left!important;
	transform: translate(-40px,20px);
	font-weight: 1000!important;
	font-size: 8px!important;
 	font-family: 'Abril Fatface', cursive!important;
}
.logo span{
	font-size: 20px;
}
.image{
	margin-top: 60%;
}
h6.mt-3{
	font-weight: bold;
}
.col-md-4 p{
	font-size: 12px;
	line-height: 15px;
	padding: 0 60px 0 60px;
	color: #0000009e;
	font-weight: 500;
}
.col-md-8{
	background: #fff;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	padding-bottom: 80px;
}
p.mb-0{
	color: #6C6D6F;
	font-size: 10px;
	font-weight: bold;
}
strong{
	font-size: 12px;
}
.fa-phone-volume{
	margin-right: 4px;
	font-size: 12px;
}
form.information{
	margin: 0 280px 0 100px;
}
h4.form-heading{
	font-weight: bold;
	font-size: 23px!important
}
p.form-para{
	font-size: 13px;
	font-weight: 500;
	color: #0000009e;
	line-height: 17px;
}
p.form-para::after{
	content: ".";
	font-size: 0;
	display: block;
	width: 8%;
	height: 4px;
	background: #6200EA;
	margin: 10px 0;
	transform: translateX(-10px);
}
.form-group {
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 90px;
}
.form-control {
    border-radius: 0;
    outline: 0;
}
.form-control:focus{
	box-shadow: none;
    border-radius: 0;
    border: 1px solid #dae0e5;;
}
.form-control-placeholder {
    position: absolute;
    top: 12px;
    left: 10px;
    transition: all 200ms;
    opacity: 0.5;
    font-size: 75%;
}

.form-control:focus+.form-control-placeholder,
.form-control:valid+.form-control-placeholder {
    font-size: 60%;
    transform: translate3d(0, -75%, 0);
    opacity: 1;
    top: 12px;
}
.btn-primary{
	border: none!important;
	background: #6200EA!important;
	height: 46px!important;
}
.btn-primary span{
	color: #E5DDFF;
	font-size: 10px;
}
.terms{
	font-size: 10px;
	color: #0000009e;
	font-weight: 500;
}
.terms a{
	color: #6200EA!important;
}


@media screen and (max-width: 767px){
.mycol1{
		border-radius: 0;
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		padding: 50px 50px 50px 50px;
	}
.image{
	margin-top: 0;
}
.mycol2{
		border-radius: 0;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
		text-align: center;
		padding-bottom: 40px;
	}
form.information{
	margin: 10px 10px 0 10px;
}
	.form-group {
    margin: 10px 10px 0 10px;
}
.logo{
	transform: translate(-90px,-30px);
}
}
@media screen and (min-width: 767px)
{
	form.information{
	margin: 0 200px 0 50px;
}
	.form-group {
    margin-right: 40px;
}
}
@media screen and (min-width: 1200px){
	form.information{
	margin: 0 280px 0 100px;
}
	.form-group {
    margin-bottom: 0.5rem;
    margin-right: 80px;
}
} */

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Open+Sans:wght@400;700&display=swap');
body{
	background: rgba(0,0,0,.075);
	}

.overy{
	overflow-y: hidden !important;
}
.container1{
	margin-top: 50px;
	margin-bottom: 50px;
}
.col-md-4{
	background: #F9F8F4;
	text-align: center;
	border-radius: 12px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.logo{
	text-align: left!important;
	transform: translate(-40px,20px);
	font-weight: 1000!important;
	font-size: 8px!important;
 	font-family: 'Abril Fatface', cursive!important;
}

.logo span{
	font-size: 20px;
}
 
h6.mt-3{
	font-weight: bold;
}
.col-md-4 p{
	font-size: 12px;
	line-height: 15px;
	padding: 0 60px 0 60px;
	color: #0000009e;
	font-weight: 500;
}
.col-md-8{
	background: #fff;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	padding-bottom: 80px;
}
p.mb-0{
	color: #6C6D6F;
	font-size: 10px;
	font-weight: bold;
}
strong{
	font-size: 12px;
}
.fa-phone-volume{
	margin-right: 4px;
	font-size: 12px;
}
form.information{
	margin: 0 280px 0 100px;
}
h4.form-heading{
	font-weight: bold;
	font-size: 23px!important
}
p.form-para{
	font-size: 13px;
	font-weight: 500;
	color: #0000009e;
	line-height: 17px;
}
p.form-para::after{
	content: ".";
	font-size: 0;
	display: block;
	width: 8%;
	height: 4px;
	background: #6200EA;
	margin: 10px 0;
	transform: translateX(-10px);
}
.form-group {
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 90px;
}
.form-control {
    border-radius: 0;
    outline: 0;
}
.form-control:focus{
	box-shadow: none;
    border-radius: 0;
    border: 1px solid #dae0e5;;
}
.form-control-placeholder {
    position: absolute;
    top: 12px;
    left: 10px;
    transition: all 200ms;
    opacity: 0.5;
    font-size: 75%;
}

.form-control:focus+.form-control-placeholder,
.form-control:valid+.form-control-placeholder {
    font-size: 60%;
    transform: translate3d(0, -75%, 0);
    opacity: 1;
    top: 12px;
}
.btn-primary{
	border: none!important;
	background: #6200EA!important;
	height: 46px!important;
}
.btn-primary span{
	color: #E5DDFF;
	font-size: 10px;
}
.terms{
	font-size: 10px;
	color: #0000009e;
	font-weight: 500;
}
.terms a{
	color: #6200EA!important;
}


@media screen and (max-width: 767px){
.mycol1{
		border-radius: 0;
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
		padding: 50px 50px 50px 50px;
	}
.image{
	margin-top: 0;
}
.mycol2{
		border-radius: 0;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
		text-align: center;
		padding-bottom: 40px;
	}
form.information{
	margin: 10px 10px 0 10px;
}
	.form-group {
    margin: 10px 10px 0 10px;
}
.logo{
	transform: translate(-90px,-30px);
}
}
@media screen and (min-width: 767px)
{
	form.information{
	margin: 0 200px 0 50px;
}
	.form-group {
    margin-right: 40px;
}
}
@media screen and (min-width: 1200px){
	form.information{
	margin: 0 280px 0 100px;
}
	.form-group {
    margin-bottom: 0.5rem;
    margin-right: 80px;
}
}