@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.sticky{
    position: fixed;
}

.col a{
    text-decoration: none;
    color: black;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,body {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;  
    height: 100%;
    overflow-x: hidden;
    background:rgb(241,243,233);
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#F1F3E9;
	color:#0C9;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}
ul {
    list-style-type: none;
}

img {
    width: 100%;
}

.lg {
    font-size: 3rem;
}

.grid {
    color:olive;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    height: 100%;
}

.griddy {
    color:olive;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 50px;
}
.grid-4 {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 50px;
}
.picha,.picha1,.picha2{
    background-color: #F1F3E9;
    color: #444;
    padding: 20px;
    font-family: 'Titillium Web', sans-serif;
    /* background-image: url("/ribbon.png");
    background-repeat: no-repeat;
    background-attachment:unset;
    background-size: cover; */
    
}


section h2{
    color: #351bcbc6;
}


@media (max-width: 768px) {
    .grid{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    .griddy{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}